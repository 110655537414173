export default{
    form: {
        startSurveyButton: "ਸ਼ੁਰੂ ਕਰੋ",
        nextButton: "ਅਗਲਾ",
        submitAnswerButton: "ਜਵਾਬ ਭੇਜੋ",
        continueButton: "ਜਾਰੀ ਰੱਖੋ",
        finishButton: "ਮੁਕੰਮਲ",
        headerPercentageCompletion: 'ਪੂਰਾ ਹੋਇਆ',
        headerQuestionsCompleted: 'ਸਵਾਲ',
        headerDefaultErrorMessage: 'ਓਹੋ। ਕੁਝ ਗਲਤ ਹੋ ਗਿਆ ਹੈ।',
        brandingPoweredBy: 'Powered by',
        brandingPromoWhySurvey: 'ਸਰਵੇ ਕਿਉਂ, ਜਦੋਂ ਤੁਸੀਂ <b>{product}</b> ਵਰਤ ਸਕਦੇ ਹੋ?',
        brandingPromoGetStarted: 'ਅੱਜ ਹੀ ਸ਼ੁਰੂ ਕਰੋ!',
        submissionPending: "ਤੁਹਾਡਾ ਵਾਇਸਫਾਰਮ ਭੇਜਿਆ ਜਾ ਰਿਹਾ ਹੈ। ਕਿਰਪਾ ਕਰਕੇ ਬ੍ਰਾਊਜ਼ਰ ਦੀ ਵਿੰਡੋ ਬੰਦ ਨਾ ਕਰੋ।",
        submissionCanCloseWindow: "ਤੁਹਾਡਾ ਵਾਇਸਫਾਰਮ ਸਫਲਤਾਪੂਰਵਕ ਭੇਜਿਆ ਗਿਆ ਹੈ। ਤੁਸੀਂ ਹੁਣ ਵਿੰਡੋ ਬੰਦ ਕਰ ਸਕਦੇ ਹੋ 👍",
        mediaAttachmentOverlayCloseButton: "ਬੰਦ ਕਰੋ",
        expiredFormHeader: "ਆਉਣ ਲਈ ਧੰਨਵਾਦ!",
        expiredFormMessage: "ਇਹ ਲੱਗਦਾ ਹੈ ਕਿ ਇਹ ਸਰਵੇ ਸਮਾਪਤ ਹੋ ਚੁੱਕੀ ਹੈ। ਵਾਇਸਫਾਰਮ ਦੇ ਸਿਰਜਣਹਾਰ ਨਾਲ ਸੰਪਰਕ ਕਰੋ।",
        notPublishedFormHeader: "ਵਾਇਸਫਾਰਮ ਪ੍ਰਕਾਸ਼ਿਤ ਨਹੀਂ ਹੋਇਆ!",
        notPublishedFormMessage: "ਇਹ ਲੱਗਦਾ ਹੈ ਕਿ ਇਹ ਸਰਵੇ ਅਜੇ ਪ੍ਰਕਾਸ਼ਿਤ ਨਹੀਂ ਹੋਇਆ। ਵਾਇਸਫਾਰਮ ਦੇ ਸਿਰਜਣਹਾਰ ਨਾਲ ਸੰਪਰਕ ਕਰੋ।",
        notPublishedFormRetryButton: "ਮੁੜ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
        errorFormHeader: "ਓਹੋ!",
        errorFormMessage: "ਸ਼ਾਇਦ ਕੁਝ ਗਲਤ ਹੋ ਗਿਆ ਹੈ। ਬਾਅਦ ਵਿੱਚ ਫਿਰ ਕੋਸ਼ਿਸ਼ ਕਰੋ।",
        errorFormRetryButton: "ਮੁੜ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
        emptyFormHeader: "ਆਉਣ ਲਈ ਧੰਨਵਾਦ!",
        emptyFormMessage: "ਓਹੋ! ਇਹ ਫਾਰਮ ਫਿਲਹਾਲ ਖਾਲੀ ਹੈ। ਕਿਰਪਾ ਕਰਕੇ ਇਸ ਸਮੱਸਿਆ ਬਾਰੇ ਸਿਰਜਣਹਾਰ ਨੂੰ ਦੱਸੋ। ਤੁਹਾਡੀ ਸਮਝ ਅਤੇ ਸਹਿਯੋਗ ਲਈ ਧੰਨਵਾਦ!",

        restoreFormHeader: "ਲੱਗਦਾ ਹੈ ਤੁਸੀਂ ਇੱਕ ਫਾਰਮ ਪ੍ਰਗਤੀ ਵਿੱਚ ਹੈ!",
        restoreFormMessage: "ਕੀ ਤੁਸੀਂ ਇਸ ਨੂੰ ਜਾਰੀ ਰੱਖਣਾ ਚਾਹੋਗੇ?",
        restoreFormButton: "ਫਾਰਮ ਜਾਰੀ ਰੱਖੋ",
        restoreFormButtonNew: "ਨਵਾਂ ਸ਼ੁਰੂ ਕਰੋ",

        audioPermissionHeader: "ਕੀ ਤੁਸੀਂ ਆਵਾਜ਼ ਨਾਲ ਜਵਾਬ ਦੇਣਾ ਚਾਹੋਗੇ?",
        audioPermissionMessage: "ਬੋਲਣ ਨਾਲ ਜਵਾਬ ਦੇਣਾ ਤੇਜ਼ ਅਤੇ ਆਸਾਨ ਹੋਵੇਗਾ।",
        audioPermissionAcceptButton: "ਹਾਂ, ਮੈਂ ਚਾਹੁੰਦਾ ਹਾਂ",
        audioPermissionDenyButton: "ਨਹੀਂ, ਧੰਨਵਾਦ",
        audioPermissionErrorHeader: "ਮਾਈਕ ਉਪਲਬਧ ਨਹੀਂ ਹੈ",
        audioPermissionErrorMessage: "ਕਿਰਪਾ ਕਰਕੇ ਬ੍ਰਾਊਜ਼ਰ ਦੀਆਂ ਅਨੁਮਤੀਆਂ ਦੀ ਜਾਂਚ ਕਰੋ ਅਤੇ ਮਾਈਕ ਦੀ ਪਹੁੰਚ ਪ੍ਰਾਪਤ ਕਰੋ।",
        audioPermissionErrorButton: "ਠੀਕ ਹੈ",
        audioRecorderFailedUpload: "ਰਿਕਾਰਡਿੰਗ ਅੱਪਲੋਡ ਕਰਨ ਵਿੱਚ ਅਸਫਲ",
        silenceDetectorError: "ਸਾਨੂੰ ਤੁਹਾਡੀ ਆਵਾਜ਼ ਨਹੀਂ ਸੁਣਾਈ ਦਿੱਤੀ। ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੇ ਮਾਈਕ ਦੀਆਂ ਸੈਟਿੰਗਾਂ ਦੀ ਜਾਂਚ ਕਰੋ।",
        audioRecorderButtonRecord: "ਰਿਕਾਰਡ ਕਰੋ",
        audioRecorderButtonRecordMore: "ਹੋਰ ਰਿਕਾਰਡ ਕਰੋ",
        voiceResponseSelectionMessage: "ਤੁਸੀਂ ਕਿਵੇਂ ਜਵਾਬ ਦੇਣਾ ਚਾਹੁੰਦੇ ਹੋ...",
        voiceResponseSelectionOrDivider: "ਜਾਂ",
        voiceResponseTextInputPlaceholder: "ਜਵਾਬ ਲਿਖੋ",
        voiceResponseTextInputCharacterCounterMinMessage: "ਕਿਰਪਾ ਕਰਕੇ ਘੱਟੋ ਘੱਟ {min} ਅੱਖਰ ਦਰਜ ਕਰੋ",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} ਅੱਖਰ (ਘੱਟੋ ਘੱਟ {min} ਅੱਖਰ)",
        voiceResponseTextInputCharacterValidationMessage: "{count} ਅੱਖਰ (ਘੱਟੋ ਘੱਟ {min} ਅੱਖਰ)",

        // checkbox
        checkboxValidationTooFew: "ਤੁਸੀਂ ਘੱਟੋ ਘੱਟ {min} ਵਿਕਲਪ ਚੁਣੋ",
        checkboxValidationTooMany: "ਤੁਸੀਂ ਵੱਧ ਤੋਂ ਵੱਧ {max} ਵਿਕਲਪ ਚੁਣੋ",
        checkboxNoneOfTheAboveOption: "ਉਪਰੋਕਤ ਵਿੱਚੋਂ ਕੋਈ ਨਹੀਂ",
        checkboxInvalidAnswer: "ਗਲਤ ਜਵਾਬ।",

        // datepicker
        datePickerPlaceholder: "ਤਾਰੀਖ ਚੁਣੋ",

        // dropdown
        // email
        emailLabel: "ਈਮੇਲ",
        emailInvalid: "ਗਲਤ ਈਮੇਲ।",
        // file-upload
        fileUploadPluginNameCamera: "ਕੈਮਰਾ",
        fileUploadPluginNameCameraVideo: "ਵੀਡੀਓ ਰਿਕਾਰਡ ਕਰੋ",
        fileUploadPluginScreenCast: "ਸਕ੍ਰੀਨ ਕਾਸਟ",
        fileUploadVideoImportFilesDefault: 'ਵੀਡੀਓ ਰਿਕਾਰਡ ਜਾਂ ਅੱਪਲੋਡ ਕਰੋ:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'ਵੀਡੀਓ ਰਿਕਾਰਡ ਕਰਨ ਦਾ ਵਿਕਲਪ ਚੁਣੋ:',
        fileUploadVideoImportFilesNoCamera: 'ਸਕ੍ਰੀਨ ਵੀਡੀਓ ਰਿਕਾਰਡ ਕਰਨ ਲਈ ਬਟਨ ਦਬਾਓ',
        fileUploadVideoImportFilesNoScreenCast: 'ਵੀਡੀਓ ਰਿਕਾਰਡ ਕਰਨ ਲਈ ਬਟਨ ਦਬਾਓ',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'ਵੀਡੀਓ ਰਿਕਾਰਡ ਕਰਨ ਲਈ ਕੋਈ ਵਿਕਲਪ ਉਪਲਬਧ ਨਹੀਂ ਹੈ',

        // matrix
        matrixValidationMessage: "ਜਵਾਬ ਸਹੀ ਹੈ।",
        matrixRow: "ਕਤਾਰ",
        matrixColumn: "ਕਾਲਮ",
        matrixRowRequired: "ਕਤਾਰ {rowTitle} ਜ਼ਰੂਰੀ ਹੈ।",
        matrixRadioGroup: "ਰੇਡੀਓ ਸਮੂਹ",
        matrixCheckboxGroup: "ਚੈਕਬਾਕਸ ਸਮੂਹ",
        matrixGroupRequired: "ਕਤਾਰ {rowTitle} ਲਈ {type} {groupTitle} ਜ਼ਰੂਰੀ ਹੈ।",
        matrixColumnRequired: "ਕਤਾਰ {rowTitle} ਲਈ ਕਾਲਮ {columnTitle} ਜ਼ਰੂਰੀ ਹੈ।",
        matrixColumnInvalid: "ਕਤਾਰ {rowTitle} ਲਈ ਕਾਲਮ {columnTitle} ਗਲਤ ਹੈ।",
        matrixRequired: "ਜਰੂਰੀ ਹੈ।",
        matrixNumericMustBeNumber: "ਇਹ ਇੱਕ ਸੰਖਿਆ ਹੋਣੀ ਚਾਹੀਦੀ ਹੈ।",
        matrixNumericMustBeGreaterThenMin: "{min} ਤੋਂ ਵੱਧ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ।",
        matrixNumericMustBeLessThenMax: "{max} ਤੋਂ ਘੱਟ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ।",
        matrixNumericMustBeInteger: "ਇਹ ਇੱਕ ਪੂਰਨ ਅੰਕ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ।",
        matrixNumericInvalidNumber: "ਗਲਤ ਸੰਖਿਆ।",

        // name
        nameLabel: "ਨਾਮ",
        nameInvalid: "ਪਹਿਲਾ ਅਤੇ ਆਖਰੀ ਨਾਮ ਦਰਜ ਕਰੋ।",

        // nps
        npsNotLikely: "ਬਿਲਕੁਲ ਸੰਭਾਵਨਾ ਨਹੀਂ",
        npsExtremelyLikely: "ਬਹੁਤ ਜ਼ਿਆਦਾ ਸੰਭਾਵਨਾ",

        // numeric input
        numericInputRequired: "ਜਰੂਰੀ ਹੈ।",
        numericInputMustBeNumber: "ਇਹ ਇੱਕ ਸੰਖਿਆ ਹੋਣੀ ਚਾਹੀਦੀ ਹੈ।",
        numericInputMustBeGreaterThenMin: "{min} ਤੋਂ ਵੱਧ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ।",
        numericInputMustBeLessThenMax: "{max} ਤੋਂ ਘੱਟ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ।",
        numericInputMustBeInteger: "ਇਹ ਇੱਕ ਪੂਰਨ ਅੰਕ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ।",
        numericInputInvalidNumber: "ਗਲਤ ਸੰਖਿਆ।",
        numericInputPlaceholder: "ਸੰਖਿਆ ਲਿਖੋ",

        // phone
        phoneInvalid: "ਗਲਤ ਫ਼ੋਨ ਨੰਬਰ।",
        phoneCountrySelectorLabel: 'ਦੇਸ਼ ਕੋਡ',
        phoneCountrySelectorError: 'ਦੇਸ਼ ਚੁਣੋ',
        phoneNumberLabel: 'ਫ਼ੋਨ ਨੰਬਰ',
        phoneExample: 'ਉਦਾਹਰਨ:',

        // boolean
        booleanYesLabel: "ਹਾਂ",
        booleanNoLabel: "ਨਹੀਂ",

        //questionStep
        questionStepAudioQuestionLabel: "ਆਡੀਓ ਸਵਾਲ",

        // errors
        invalidPhoneNumber: "{phone} ਗਲਤ ਫ਼ੋਨ ਨੰਬਰ ਹੈ।",
        invalidEmail: "{email} ਗਲਤ ਈਮੇਲ ਪਤਾ ਹੈ।",
        questionIsRequired: "ਇਹ ਸਵਾਲ ਜ਼ਰੂਰੀ ਹੈ।",
        answerIsNotValid: "ਜਵਾਬ ਗਲਤ ਹੈ।",
        unfinishedProbingDialogError: "ਕਿਰਪਾ ਕਰਕੇ ਸੰਵਾਦ ਮੁਕੰਮਲ ਕਰੋ।",
        cannotResumePartialResponse: "ਅਧੂਰੇ ਜਵਾਬ ਨੂੰ ਮੁੜ ਸ਼ੁਰੂ ਨਹੀਂ ਕੀਤਾ ਜਾ ਸਕਦਾ।",
        failedToSubmitForm: "ਫਾਰਮ ਭੇਜਣ ਵਿੱਚ ਅਸਫਲਤਾ। ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੇ ਇੰਟਰਨੈਟ ਕੁਨੈਕਸ਼ਨ ਦੀ ਜਾਂਚ ਕਰੋ ਅਤੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ।"
    }
}
